import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, map, of, throwError } from 'rxjs';
import { constant } from './../../constants/app.constants';
import { UserModel } from '../../modules/myPrimeRx/models/user.model'; 
import { Router } from '@angular/router'; 
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}; 

@Injectable({
  providedIn: 'root'
})
export class PeAuthService { 

  public _activeElinkUserSub$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public elinkData$: Observable<any> = this._activeElinkUserSub$.asObservable();

  constructor(private http:HttpClient,private _router: Router) { } 

  getElinkData(id: string, guid: string, npi: string): Observable<any> {
    const url = environment.API_BASE_URL + constant.ELINK_GET.replace("{id}", "" + id)
                                                      .replace("{guid}", "" + guid)
                                                      .replace("{npi}", "" + npi);
    return this.http.get<any>(url);
  }
  
  logout(): void  { 
    this._activeElinkUserSub$.next(null);
    localStorage.clear(); 
    this._router.navigate(['/login']);
  }
}