<div class="auth0-login-grid">
    <div class="login-user">
        <div class="login-pharma">
            <div class="login-pharma-img">
            <span>Your</span>
            <span>Pharmacy Logo</span>
            <span>Ph: (516) 408-3999</span>
        </div>
            <div class="login-pharma-a laptop">
                <img alt="Pharmacy" src="../assets/images/auth-img.png">
            </div>
        </div>
        <div class="login-access">
            <div class="title">Login</div>
            <div class="error-msg">Login with the data you entered during your registration.</div>
            <div class="login-form">
                <form onsubmit="return false;" method="post">
                        <div class="form-group">
                        <label for="name">User Name</label>
                        <input type="email" class="form-control" id="email" placeholder="Enter your email">
                        </div>
                        <div class="form-group">
                        <label for="name">Password</label>
                        <input type="password" class="form-control" id="password" placeholder="Enter your password">
                        </div>
                        <div class="captcha-container form-group"></div>
                        <button type="submit" id="btn-login" class="btn btn-primary btn-block">Login</button>
                        <div class="doyou">Don't have an account? <button type="button" id="btn-signup" class="btn">Sign Up</button></div>
                        
                        <!--<hr>
                        <button type="button" id="btn-google" class="btn btn-default btn-danger btn-block">Log In with Google</button>-->
                </form>
            </div>
            <div class="powerd-by">Powered by <img src="../assets/images/login-powerby.svg" alt="Powered by"></div>
        </div>
    </div>
    </div>
    <div class="copyRight">© Copyright 2024. All rights reserved.</div>