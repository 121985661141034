import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrl: './user-login.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class UserLoginComponent {

}
